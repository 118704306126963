import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import Loader from "../components/Helper/Loader";

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// ------------------------- Containers -------------------------

const AboutUs = React.lazy(() => import("../containers/AboutUs"));
const InValidSession = React.lazy(() =>
  import("../containers/InValidSession")
);
const AgentCertification = React.lazy(() =>
  import("../containers/AgentCertification")
);
const AgentDashboard = React.lazy(() => import("../containers/AgentDashboard"));
const AgentProfile = React.lazy(() => import("../containers/AgentProfile"));
const Checkout = React.lazy(() => import("../containers/Checkout"));
const ContactUs = React.lazy(() => import("../components/Container/ContactUs"));
const CourseDocument = React.lazy(() => import("../containers/CourseDocument"));
const CustomerSupport = React.lazy(() =>
  import("../containers/CustomerSupport")
);
const ExamCongratulation = React.lazy(() =>
  import("../containers/ExamCongratulation")
);
const CourseList = React.lazy(() => import("../containers/CourseList"));
const CourseExam = React.lazy(() => import("../containers/CourseExam"));
const ForgotPassword = React.lazy(() => import("../containers/ForgotPassword"));
const HeadOffices = React.lazy(() =>
  import("../components/Container/HeadOffices")
);
const Home = React.lazy(() => import("../containers/Home"));
const InsuranceTypeList = React.lazy(() =>
  import("../containers/InsuranceTypeList")
);
const LeadList = React.lazy(() => import("../containers/LeadList"));
const SalesList = React.lazy(() => import("../containers/SalesList"));

const NewExistingPolicySelection = React.lazy(() =>
  import("../containers/NewExistingPolicySelection")
);
const OTPVerification = React.lazy(() =>
  import("../containers/OTPVerification")
);
const OurPhilosophy = React.lazy(() => import("../containers/OurPhilosophy"));
const PolicyDetail = React.lazy(() => import("../containers/PolicyDetail"));
const PolicyInformationCollection = React.lazy(() =>
  import("../containers/PolicyInformationCollection")
);
const PolicyList = React.lazy(() => import("../containers/PolicyList"));
const PolicyPayment = React.lazy(() => import("../containers/PolicyPayment"));
const PolicyPaymentStatus = React.lazy(() =>
  import("../containers/PolicyPaymentStatus")
);
const PolicyStatus = React.lazy(() => import("../containers/PolicyStatus"));
const RegistrationActivationStatus = React.lazy(() =>
  import("../containers/RegistrationActivationStatus")
);

const ResetPassword = React.lazy(() => import("../containers/ResetPassword"));

const SignIn = React.lazy(() => import("../containers/SignIn"));
const SignInWelcome = React.lazy(() => import("../containers/SignInWelcome"));
const SignUp = React.lazy(() => import("../containers/SignUp"));

// ------------------------- Paths -------------------------

export const pathsUniversal = [
  "/checkout",
  "/home",
  "/new-existing-policy-selection",
  "/policy-detail",
  "/policy-information-collection",
  "/policy-list",
  "/policy-payment",
  "/policy-status",
  "/policy-payment-status",
  "contact-us",
  "head-offices",
  "customer-support",
  "about-us",
  "policy-status",
];

export const pathsUnAuthenticated = [
  "/otp-verification",
  "/registration-activation-status",
  "/signin",
  "/signin-welcome",
  "/signup",
  "/forgot-password",
  "reset-password",
];

export const pathsAuthenticated = [
  "/agent-certification",
  "/agent-dashboard",
  "/agent-profile",
  "/exam-congratulation",
  "/course-list",
  "/course-exam",
  "/insurance-type-list",
  "/lead-list",
  "/sales-list",
];

// ------------------------- Router -------------------------

export default class index extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  isRedirectPathExistIn = (paths) => {
    const isExist =
      paths.find((item) => window.location.href.includes(item)) != null;
    return isExist;
  };

  render() {
    const { isUserAuthenticated } = this.props;

    if (window.location.pathname === "/") {
      return <Redirect to="/home" />;
    }

    if (!this.isRedirectPathExistIn(pathsUniversal)) {
      if (isUserAuthenticated) {
        if (this.isRedirectPathExistIn(pathsUnAuthenticated)) {
          return <Redirect to="/agent-dashboard" />;
        }
      } else {
        if (this.isRedirectPathExistIn(pathsAuthenticated)) {
          return <Redirect to="/home" />;
        }
      }
    }

    return (
      <Switch>
        <React.Suspense fallback={<Loader />}>
          <Route
            path="/about-us"
            name="AboutUs"
            render={(props) => <AboutUs {...props} />}
          />
          <Route
            path="/agent-certification"
            name="AgentCertification"
            render={(props) => <AgentCertification {...props} />}
          />
          <Route
            path="/agent-dashboard"
            name="AgentDashboard"
            render={(props) => <AgentDashboard {...props} />}
          />
          <Route
            path="/agent-profile"
            name="AgentProfile"
            render={(props) => <AgentProfile {...props} />}
          />
          <Route
            path="/checkout"
            name="Checkout"
            render={(props) => <Checkout {...props} />}
          />
          <Route
            path="/contact-us"
            name="ContactUs"
            render={(props) => <ContactUs {...props} />}
          />
          <Route
            path="/course-document"
            name="CourseDocument"
            render={(props) => <CourseDocument {...props} />}
          />
          <Route
            path="/exam-congratulation"
            name="ExamCongratulation"
            render={(props) => <ExamCongratulation {...props} />}
          />
          <Route
            path="/course-list"
            name="CourseList"
            render={(props) => <CourseList {...props} />}
          />
          <Route
            path="/course-exam"
            name="CourseExam"
            render={(props) => <CourseExam {...props} />}
          />
          <Route
            path="/customer-support"
            name="CustomerSupport"
            render={(props) => <CustomerSupport {...props} />}
          />
          <Route
            path="/forgot-password"
            name="ForgotPassword"
            render={(props) => <ForgotPassword {...props} />}
          />
          <Route
            path="/head-offices"
            name="HeadOffices"
            render={(props) => <HeadOffices {...props} />}
          />
          <Route
            path="/home"
            name="Home"
            render={(props) => <Home {...props} />}
          />
          <Route
            path="/insurance-type-list"
            name="InsuranceTypeList"
            render={(props) => <InsuranceTypeList {...props} />}
          />
          <Route
            path="/lead-list"
            name="LeadList"
            render={(props) => <LeadList {...props} />}
          />

          <Route
            path="/sales-list"
            name="SalesList"
            render={(props) => <SalesList {...props} />}
          />

          <Route
            path="/new-existing-policy-selection"
            name="NewExistingPolicySelection"
            render={(props) => <NewExistingPolicySelection {...props} />}
          />
          <Route
            path="/otp-verification"
            name="OTPVerification"
            render={(props) => <OTPVerification {...props} />}
          />
          <Route
            path="/our-philosophy"
            name="OurPhilosophy"
            render={(props) => <OurPhilosophy {...props} />}
          />
          <Route
            path="/policy-detail"
            name="PolicyDetail"
            render={(props) => <PolicyDetail {...props} />}
          />
          <Route
            path="/policy-information-collection"
            name="PolicyInformationCollection"
            render={(props) => <PolicyInformationCollection {...props} />}
          />
          <Route
            path="/policy-list"
            name="PolicyList"
            render={(props) => <PolicyList {...props} />}
          />

          <Route
            path="/policy-payment/:type"
            name="PolicyPayment"
            render={(props) => <PolicyPayment {...props} />}
          />

          <Route
            path="/policy-status/:type"
            name="PolicyStatus"
            render={(props) => <PolicyStatus {...props} />}
          />

          <Route
            path="/policy-payment-status"
            name="PolicyPaymentStatus"
            render={(props) => <PolicyPaymentStatus {...props} />}
          />
          <Route
            path="/registration-activation-status"
            name="RegistrationActivationStatus"
            render={(props) => <RegistrationActivationStatus {...props} />}
          />
          <Route
            path="/reset-password"
            name="ResetPassword"
            render={(props) => <ResetPassword {...props} />}
          />
          <Route
            path="/signin"
            name="SignIn"
            render={(props) => <SignIn {...props} />}
          />
          <Route
            path="/signin-welcome"
            name="SignInWelcome"
            render={(props) => <SignInWelcome {...props} />}
          />
          <Route
            path="/signup"
            name="SignUp"
            render={(props) => <SignUp {...props} />}
          />
          <Route
            path="/inValidSession"
            name="InValidSession"
            render={(props) => <InValidSession {...props} />}
          />
        </React.Suspense>
      </Switch>
    );
  }
}
