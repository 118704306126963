import keyMirror from "fbjs/lib/keyMirror";

export const ActionTypes = keyMirror({
  FETCH_INITIATED: undefined,
  FETCH_COMPLETED: undefined,

  UNAUTHENTICATED_REQUEST: undefined,

  USER_LOGOUT: undefined,

  UPDATE_IS_AUTHENTICATED: undefined,

  BUSINESS_EXCEPTION: undefined,

  CHECK_USER_PROFILE_COMPLETION: undefined,
  CHECK_USER_PROFILE_COMPLETION_RESPONSE: undefined,

  FIND_ALL_INSURER_TYPES: undefined,
  FIND_ALL_INSURER_TYPES_RESPONSE: undefined,

  GET_AGENT_PRODUCT_MAPPING: undefined,
  GET_AGENT_PRODUCT_MAPPING_RESPONSE: undefined,
});
 